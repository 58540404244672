import Home from "./Home"
// import Counter from "./Counter";
import ComingSoon from "./comingsoon";
import react  from 'react'
import { render } from "react-dom";
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/breeding" element={<ComingSoon />} />
    </Routes>
    
  );
}

export default App;
