import React, { useState, useEffect } from "react";
import abi from "./abi.json";
import Web3 from "web3";
import detectEthereumProvider from "@metamask/detect-provider";

// import videoPop from "./Imgs/Video/VideoPop.mp4";

const REACT_APP_CONTRACT_ADDRESS = "0x47F51565F16B7B728545C442fB2b07b724368615";
const SELECTEDNETWORK = "1";
const SELECTEDNETWORKNAME = "Ethereum Mainnet";
const nftquantity = 10000;

function Mintbtn() {
  const [errormsg, setErrorMsg] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [totalSupply, settotalSupply] = useState(0);
  const [walletConnected, setWalletConnected] = useState(0);
  const [whitelistedUser, setWhitelistedUser] = useState(0);
  const [userAddress, setUserAddress] = useState("");
  const [popview, setpopview] = useState("none");

  useEffect(async () => {
    if (await detectEthereumProvider()) {
      // setProvider(true);
      window.web3 = new Web3(window.ethereum);
      const web3 = window.web3;
      if ((await web3.eth.net.getId()) == SELECTEDNETWORK) {
        const contractaddress = REACT_APP_CONTRACT_ADDRESS;
        const ct = new web3.eth.Contract(abi, contractaddress);
        settotalSupply(await ct.methods.totalSupply().call());

        if (nftquantity - (await ct.methods.totalSupply().call()) == 0) {
          setErrorMsg("All NFTs minted, Sale has ended");
        }
      } else {
        // setProvider(false);
        setErrorMsg(
          'Select "' +
          SELECTEDNETWORKNAME +
          '" network in your wallet to buy the nft'
        );
      }
    } else {
      setErrorMsg(
        "Non-Ethereum browser detected. You should consider trying MetaMask!"
      );
      // setProvider(false);
    }
    if (window.ethereum) {
      handleEthereum();
    } else {
      window.addEventListener("ethereum#initialized", handleEthereum, {
        once: true,
      });
      setTimeout(handleEthereum, 10000);
    }

    function handleEthereum() {
      const { ethereum } = window;
      if (ethereum) {
        console.log("Ethereum successfully detected!");
        // setProvider(true);
      } else {
        setErrorMsg("Please install MetaMask!");
        // setProvider(false);
      }
    }
  }, []);

  async function loadWeb3() {
    if (await detectEthereumProvider()) {
      window.web3 = new Web3(window.ethereum);
      await window.ethereum.enable();
      const web3 = window.web3;
      // Meta Mask Connected Account Address
      let metaMaskAccount = await web3.eth.getAccounts();
      metaMaskAccount = metaMaskAccount[0];

      if ((await web3.eth.net.getId()) == SELECTEDNETWORK) {
        // // creating contract instance
        const contractaddress = REACT_APP_CONTRACT_ADDRESS;
        const ct = new web3.eth.Contract(abi, contractaddress);

        console.log(ct);

        let current = await ct.methods.totalSupply().call();
        if (Number(current) === nftquantity) {
          console.log("Sold out");
          return;
        }

        let R1_price = await ct.methods.Round1_price().call(); //await ct.methods.getPrice().call();
        let R2_price = await ct.methods.Round2_price().call(); //await ct.methods.getPrice().call();
        let R3_price = await ct.methods.Round3_price().call(); //await ct.methods.getPrice().call();
        let WLprice = await ct.methods.Wl_price().call(); //await ct.methods.getPrice().call();

        let Wl_status = await ct.methods.Wl_status.call(); //await ct.methods.getPrice().call();
        let Round1_status = await ct.methods.Round1_status().call(); //await ct.methods.getPrice().call();
        let Round2_status = await ct.methods.Round2_status().call(); //await ct.methods.getPrice().call();
        let Round3_status = await ct.methods.Round3_status().call(); //await ct.methods.getPrice().call();

        let Event_counter = await ct.methods.Event_counter().call(); //await ct.methods.getPrice().call();


        console.log("sale statues", Wl_status)
        console.log("sale statues", Round1_status)
        console.log("sale statues", Round2_status)
        console.log("sale statues", Round3_status)


        if (Wl_status == true) {
          let iswhitelist = await ct.methods.isWhitelisted(metaMaskAccount).call();
          console.log("iswhitelisted", iswhitelist)

          if (iswhitelist == true) {
            await ct.methods.Wl_Mint(quantity).send({ from: metaMaskAccount, value: WLprice * quantity, })
          }
          else {
            setErrorMsg(
              'Whitelist Sale is Active but you are not WhiteListed'
            );
          }

        }

        else if (Round1_status == true) {
          if (quantity > 0 && quantity <= 20) {
            await ct.methods.Round1_mint(quantity).send({ from: metaMaskAccount, value: R1_price * quantity, })
          }
          else {
            setErrorMsg(
              'Can Not Mint more that 20 NFTs'
            );
          }
        }

        else if (Round2_status == true) {
          if (quantity > 0 && quantity <= 20) {
            await ct.methods.Round2_Mint(quantity).send({ from: metaMaskAccount, value: R2_price * quantity, })
          }
          else {
            setErrorMsg(
              'Can Not Mint more that 20 NFTs'
            );
          }
        }

        else if (Round3_status == true) {
          if (quantity > 0 && quantity <= 20) {
            await ct.methods.Round3_mint(quantity).send({ from: metaMaskAccount, value: R2_price * quantity, })
          }
          else {
            setErrorMsg(
              'Can Not Mint more that 20 NFTs'
            );
          }
        }


















        else {
          setErrorMsg(
            'Sale Has Not started yet'
          );
        }





        settotalSupply(await ct.methods.totalSupply().call());
        setQuantity(1);
      } else {
        setErrorMsg(
          'Select "' +
          SELECTEDNETWORKNAME +
          '" network in your wallet to buy the nft'
        );
      }
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
    } else {
      // window.alert(
      //   "Non-Ethereum browser detected. You should consider trying MetaMask!"
      // );
      {
        setErrorMsg(
          "Non-Ethereum browser detected. You should consider trying MetaMask!"
        );
      }
    }
  }

  return (
    <div>
      {!errormsg ? (
        <div className="row mintingsection">
          <div className="col-sm-12">
            <div className="yellow">
              <div style={{ display: "flex", flexDirection: "column", alignItems: "center", margin: "auto", width: "320px" }} className="mt-2">
                <h3 style={{ color: "white", letterSpacing: "3px", fontSize: "46px" }} className="">Quantity</h3>
                {/* <div style={{ marginLeft: "10px" }}> */}
                <div>
                  <button className="minus back-button btn-warning px-3 mx-1"
                    onClick={() => {
                      if (quantity > 1) {
                        setQuantity(quantity - 1);
                      }

                    }} disabled={quantity == 1}

                  >-</button>
                  <span style={{ color: "white", letterSpacing: "3px", fontSize: "46px", fontSize: 30, margin: "0 15px", }}>
                    {quantity}
                  </span>
                  <button
                    className="plus back-button btn-warning px-3 mx-1"
                    onClick={() => {
                      if (quantity < 2000) {
                        setQuantity(quantity + 1);
                      }
                    }} disabled={quantity == 20}

                  >+</button>
                </div>
              </div>
            </div>
            <button style={{
              cursor: "pointer",
              backgroundColor: "white",
              color: "#d5ac1e",
              padding: "5px 20px",
              borderRadius: "6px",
              width: '205px',
              height: '62px',
              fontSize: '35px'
            }}
              className="mt-3 mint-btn mx-auto d-block"
              onClick={() => {
                loadWeb3();
              }}
            >Mint</button>
            <h5 className="mt-2 supplytext text-center">{nftquantity - totalSupply}/{nftquantity} Available</h5>
          </div>
        </div>) : <h5 style={{ display: "flex", justifyContent: "center" }} className="mt-2 supplytext" ><b>{errormsg}</b></h5>
      }
    </div >
    // <div className="BtnDiv ">
    //   {!errormsg ? (
    //     <div className="align-items-center">
    //       {walletConnected == 0 ? (
    //         <div className="">
    //           <h4
    //             onClick={() => {
    //               loadWeb3();
    //             }}
    //             className="text-white text-center d-block w-100"
    //           >
    //             <a
    //               style={{
    //                 cursor: "pointer",
    //                 backgroundColor: "white",
    //                 color: "#d5ac1e",
    //                 padding: "5px 20px",
    //                 borderRadius: "6px",
    //               }}
    //             >
    //               Mint Now
    //             </a>
    //           </h4>
    //         </div>
    //       ) : (
    //         ""
    //       )}
    //     </div>
    //   ) : (
    //     <h5 className="mt-2 supplytext text-center">
    //       <b>{errormsg}</b>
    //     </h5>
    //   )}

    // </div>
  );
}

export default Mintbtn;
