import Counter from "./Counter";
import Mintbtn from "./mintbtn.js";
import Navbar from "./navbar.js";
import placeholder from "./images/placeholder.jpeg";
import bandana from "./images/bandana.png";
import eth from "./images/eth.png";
import spray from "./images/spray.png";
import oslogo from "./images/oslogo.webp";
import discord from "./images/Discord.webp";
import fb from "./images/FB.webp";
import instagram from "./images/Instgram.webp";
import twitter from "./images/twitter.webp";
import sagi from "./images/sagi.png";
import moar from "./images/moar.png";
import Comingsoon from './comingsoon';
import video123 from "./images/sample-1.mp4"
import nftbg from "./images/bgremove_billion_sheep_club-removebg-preview.png";

import "./App.css";
import "animate.css";
import Slide from "react-reveal/Slide";
import Flip from "react-reveal/Flip";
// import Fade from 'react-fade';


function Home() {
  return (
    <div className="container-fluid">
      <div className="hero">
        <Navbar />
        <div className="container">
          <img src={nftbg} className=" nftbg"></img>
          <h1 className="margindist  text-center pb-5 mb-2">
            Billionaire Sheep club
          </h1>
          <Counter />
        </div>
      </div>
      <div className=" pt-5 container">
        <div className="row mb-5 pb-5">
          <div className="col-md-7">
            <div className="intro mb-5 pt-5" id="mint">

              <h1 className="titletext1 tex t-center pt-5 mt-5">
                Welcome to the Billionaire Sheep club
              </h1>
              <p className="text-white text-lg t ext-center">
                Billionaire Sheep Club is a unique collection of 10,000 Sheeps
                built on the ERC20 network.
              </p>
              <p className="text-white text-lg te xt-center">
                Join the billionaire sheep frenzy as we are about to take over
                the world & fly to the moon!
              </p>
            </div>
          </div>

          <div className="col-md-5">
            <img
              src={placeholder}
              className="pb-5 mx-auto d-block rotate imgs w-100"
            />
            <Mintbtn />
            {/* <button className="mt-3 mint-btn mx-auto d-block">
              Mint Coming Very Soon
            </button> */}
            {/* <a className="text-center text-white infotext d-block">Smart Contract Address</a> */}
          </div>
        </div>
        {/* <div className="container-fluid">
          <div class="responsive">
            <iframe src={video123} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen width="2000" height="600"></iframe>
          </div></div> */}




        <div className="row container maincards ">
          <div className="row  cards  ">
            <div className="col-md-4 px-2 background">
              <img src={spray} className="d-block mx-auto pb-2   " />
              <p className="text-center  infotext">
                Get yours now before it’s too late! Many unique benefits and
                exclusive access for holders & much more to look forward to as
                we move forward as a community.{" "}
              </p>
            </div>

            <div className="col-md-4 px-2 background">
              <img src={eth} className="d-block mx-auto pb-3" />
              <p className="text-center e infotext">
                AI generated sheeps with over 100 unique traits! Variety of
                expressions and exclusive styles. Each Sheep has its own unique
                rarity and rank.{" "}
              </p>
            </div>

            <div className="col-md-4 px-2 background">
              <img src={bandana} className="d-block mx-auto pb-3" />
              <p className="text-center  infotext">
                By owning a Billionaire Sheep, you will be automatically
                eligible for exclusive giveaways, monthly raffles, whitelists &
                much more .{" "}
              </p>
            </div>
          </div>
          <p className="text-center text-white pt-3">
            By owning a Billionaire Sheep, you will be automatically eligible
            for exclusive giveaways, monthly raffles, whitelists & much more .
          </p>
        </div>
      </div>
      <br></br>

      <div class="responsive descreaption mb-5">

        <video autoPlay muted loop>
          <source src={video123} type="video/mp4" />
        </video>
      </div>


      <div className="row py-5 mt-5  px-5  story">
        <div className="col-md-12 ">
          <h1 className="titletext text-center py-5">Creator royalty 10% </h1>

          <h2 className="titletext2" id="about">
            THE STORY...
          </h2>
          <p className="  titletext3">
            They started at an old farm, As ordinary sheeps with big dreams,
            they always wanted to leave but the owners of the Farm would not let
            them get away.


            Until one day they decided they had enough, took over the farm,
            threw the owners away and started their own business. Now they’re
            Hi-tech billionaires living on the Ethereum blockchain .{" "}


            Army of 10,000 Sheeps is going wild all around the world, you can
            find them at exclusive clubs, around yachts, and flying around in
            private jets. The billionaire sheeps are known for their lavish
            money spending life-style, Everyone envies them as they know those
            sheeps are badass.
          </p>
        </div>
      </div>

      <div className="team pt-5 px-5 container">
        <div className="row">
          <div className="col-md-12">
            <h1 className="titletext text-center pb-5">Team</h1>
          </div>
        </div>
        <div className="row justify-content-center ">
          <div className="col-md-3 px-4">
            <img src={sagi} className="d-block mx-auto teamimg w-100" />
            <h3 className="teamname pt-4">Sagi Giovanni</h3>
            <p className="text-center text-white">
              Creative Director / Founder
            </p>
          </div>

          <div className="col-md-3 px-4">
            <img src={moar} className="d-block mx-auto teamimg w-100" />
            <h3 className="teamname pt-4">Traffic Trust</h3>
            <p className="text-center text-white">Investor / Co-founder</p>
          </div>
        </div>
        {/* <p className="text-center text-white pt-3">
          We have come together to bring you the Hood Raffes NFT collection! <br />
          Our team contains members from different races, backgrounds, nationalities and genders and we believe in equality for all!
        </p> */}
      </div>

      <div className="team pt-5 mt-5 px-5 container text-white">
        <div className="row">
          <div className="col-md-12">
            <h1 className="titletext text-center pb-2 roadmap" id="roadmap">
              Billionaire Sheep Club Roadmap
            </h1>
            <p className="text-white billion text-center text-lg">
              NFT Disturbiution<br></br>
              Total sheeps - 10,000 NFT<br></br>
              Total Cyber Sheeps - 5,000 NFT(GEN2)<br></br>
              Discord & Twitter Events (100 sheeps)<br></br>
              Presale to white-listed (900 sheeps)- 0.35 ETH<br></br>
              Round 1 ( 2000 SHEEPS) - 0.5 ETH<br></br>
              Round 2 (3000 SHEEPS) - 0.75 ETH<br></br>
              Round 3 (4000 SHEEPS) - 1 ETH<br></br>

              Special events airdrop (100 sheeps)<br></br>
              Discord & Twitter Events (100 sheeps)<br></br>

            </p>
          </div>
        </div>

        <div class="timeline">
          <div class="abc left">
            <div class="date titletext text-center">10%</div>
            <i class="icon fa fa-home"></i>

            <div class="content">
              {/* <h2>Lorem ipsum dolor sit amet</h2> */}
              <Slide left>
                <p className=" ">
                  NFT Designs<br></br>
                  Website Design<br></br>
                  1,000 billionaire sheeps with lavish lifestyles will be minted
                  and introduced to the world.
                </p>
              </Slide>
            </div>
          </div>

          <div class="abc right">
            <div class="date titletext text-center">20%</div>
            <i class="icon fa fa-gift"></i>
            <div class="content">
              {/* <h2>Lorem ipsum dolor sit amet</h2> */}
              <Slide right>
                <p>
                  Website Launch Social medias Launch<br></br>
                  Community build up <br></br>
                  Hyper-Aggressive Marketing and hype generation<br></br>
                  including social + influencers
                </p>
              </Slide>
            </div>
          </div>

          <div class="abc left">
            <div class="date titletext text-center">30%</div>
            <i class="icon fa fa-user"></i>
            <div class="content">
              {/* <h2>Lorem ipsum dolor sit amet</h2> */}
              <Slide left>
                <p>Round 2 of Presale- (2000 Sheeps) </p>
              </Slide>
            </div>
          </div>

          <div class="abc right">
            <div class="date titletext text-center">40%</div>
            <i class="icon fa fa-running"></i>
            <div class="content">
              {/* <h2>Lorem ipsum dolor sit amet</h2> */}
              <Slide right>
                <p>Public Sale - 3,000 sheeps </p>
              </Slide>
            </div>
          </div>

          <div class="abc left">
            <div class="date titletext text-center">50%</div>
            <i class="icon fa fa-cog"></i>
            <div class="content">
              {/* <h2>Lorem ipsum dolor sit amet</h2> */}
              <Slide left>
                <p>Open public sale round 2 - (5,000 sheeps) </p>
              </Slide>
            </div>
          </div>

          <div class="abc right">
            <div class="date titletext text-center">60%</div>
            <i class="icon fa fa-certificate"></i>
            <div class="content">
              {/* <h2>Lorem ipsum dolor sit amet</h2> */}
              <Slide right>
                <p>
                  20% off all profits goes into Staking and generates rewards
                  <br></br> for lucky HOLDERS<br></br>
                  Sheep Breeding System trailers
                </p>
              </Slide>
            </div>
          </div>

          <div class="abc left">
            <div class="date titletext text-center">70%</div>
            <i class="icon fa fa-cog"></i>
            <div class="content">
              {/* <h2>Lorem ipsum dolor sit amet</h2> */}
              <Slide left>
                <p>
                  Purchasing land in metaverse to create the “Billionaire sheep
                  club”<br></br> which is a club for invited community members
                  only, certain<br></br> holders will be able to enter unique
                  events.{" "}
                </p>
              </Slide>
            </div>
          </div>

          <div class="abc right">
            <div class="date titletext text-center">80%</div>
            <i class="icon fa fa-certificate"></i>
            <div class="content">
              {/* <h2>Lorem ipsum dolor sit amet</h2> */}
              <Slide right>
                <p>
                  Sheep Breeding System LAUNCH<br></br>
                  CyberSheeps NFT design reveal
                </p>
              </Slide>
            </div>
          </div>

          <div class="abc left">
            <div class="date titletext text-center">90%</div>
            <i class="icon fa fa-cog"></i>
            <div class="content">
              {/* <h2>Lorem ipsum dolor sit amet</h2> */}
              <Slide left>
                <p>
                  CyberSheep Launch,<br></br>
                  5,000 NFT’s of new 3D Cyber Sheeps will be minted to the
                  world.
                </p>
              </Slide>
            </div>
          </div>

          <div class="abc right">
            <div class="date titletext text-center">100%</div>
            <i class="icon fa fa-certificate"></i>
            <div class="content">
              {/* <h2>Lorem ipsum dolor sit amet</h2> */}
              <Slide right>
                <p>Multi-Chain Collections.</p>
              </Slide>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <h1 className="supplytext pt-5 pb-2 text-center">Check us out on</h1>
            <a href="https://opensea.io/BillionaireSheepClub"><img className="oslogo pt-3" src={oslogo} /></a>
            <span className="socials oslogo pt-3 ">
              <a href="https://discord.gg/kzyctCBTC8">
                <img className="socialicon " src={discord} />
              </a>
              <a href="https://twitter.com/BillionaireShe1">
                <img className="socialicon" src={twitter} />
              </a>
              <a href="https://www.facebook.com/Billionaire-Sheep-Club-104094652177728">
                <img className="socialicon" src={fb} />
              </a>
              <a href="https://www.instagram.com/billionairesheepclub/">
                <img className="socialicon" src={instagram} />
              </a>
            </span>
          </div>
        </div>
      </div>
      <hr className="mt-4 mb-2" />
      <small className="text-white text-center d-block pb-2">© 2022</small>
    </div>
  );
}

export default Home;
