import React from "react";

import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.min.js';
import "./comingsoon.css"


function ComingSoon() {
    return (

                    <div class="container-fluid maindiv m-0 p-0">
                    
                            <div class="row row1">
                               

                                <div class="col-6 middlediv">
                                    <p className="fonts  websitename">            Billionaire Sheep club
</p>
                                    <h1 className="fonts  comingsoontext">Coming Soon! </h1>
                                </div>

                 
                            </div>
                    </div>


    );

    }
export default ComingSoon;